import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  PopupSettings,
  ToolBarComponent,
} from '@progress/kendo-angular-toolbar';
import {
  ExtendedGkKendoGridComponent,
  GenericToolbarModifyGeometryPayload,
  GenericToolbarShowGeometryPayload,
  GridToolBarItem,
  GridToolBarItemType,
} from '../gk-kendo-grid.model';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { iwIconSelectAll } from '../../../assets/css/ewid-svg/svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { GkKendoMapBase } from '../gk-kendo-map-base';
import { MapAction, MapObjectTableActionType } from '../../../gk-map/models';

@Component({
  selector: 'gk-kendo-grid-toolbar',
  templateUrl: './gk-kendo-grid-toolbar.component.html',
  styleUrl: './gk-kendo-grid-toolbar.component.scss',
})
export class GkKendoGridToolbarComponent
  extends GkKendoMapBase
  implements OnInit
{
  @Input() gridToolbarItems: GridToolBarItem[] = [];
  @Input() selectable: SelectableSettings | boolean = {
    enabled: true,
    mode: 'single',
  };
  @Input() parent: ExtendedGkKendoGridComponent<any>;
  @Input() position: 'top' | 'bottom';
  @Input() overflow = false;
  public iwIconSelectAll = iwIconSelectAll;
  gridToolbarPopupSettings: PopupSettings = {
    popupClass: 'vw-100-mobile',
  };
  protected readonly gridToolBarButtonType = GridToolBarItemType;
  @ViewChild('kendoToolbar') private kendoToolbar: ToolBarComponent;

  constructor(
    public deviceService: DeviceDetectorService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToMapActions();
  }

  getButtonClass(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.className === 'function') {
      return gridToolbarItem.className();
    }

    return gridToolbarItem.className;
  }

  getIconClass(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.iconClass === 'function') {
      return gridToolbarItem.iconClass();
    }
    return gridToolbarItem.iconClass;
  }

  getSvgIcon(gridToolbarItem: GridToolBarItem): SVGIcon {
    if (typeof gridToolbarItem.svgIcon === 'function') {
      return gridToolbarItem.svgIcon();
    }
    return gridToolbarItem.svgIcon;
  }

  onSelectAllGridItemsClick(): void {
    this.parent.gridComponent.selectionService.updateAll(true);
  }

  getToolbarItemDisabledState(gridToolbarItem: GridToolBarItem): boolean {
    if (typeof gridToolbarItem.disabled === 'function') {
      return gridToolbarItem.disabled();
    }

    return gridToolbarItem.disabled;
  }

  getToolbarItemVisibleState(
    visible: boolean | (() => boolean) | undefined,
    gridToolBarItemType?: GridToolBarItemType,
  ): boolean | GridToolBarItemType {
    if (visible === true || (typeof visible === 'function' && visible())) {
      return gridToolBarItemType || true;
    }
    if (visible === false || (typeof visible === 'function' && !visible())) {
      return false;
    }

    return gridToolBarItemType || true;
  }

  getToolbarItemText(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.text === 'function') {
      return gridToolbarItem.text();
    }
    return gridToolbarItem.text;
  }

  getToolbarItemTitle(gridToolbarItem: GridToolBarItem): string {
    if (typeof gridToolbarItem.title === 'function') {
      return gridToolbarItem.title();
    }
    return gridToolbarItem.title;
  }

  getToolbarItemSelectedValue(
    gridToolbarItem: GridToolBarItem,
  ): boolean | undefined {
    if (typeof gridToolbarItem.selected === 'function') {
      return gridToolbarItem.selected();
    }

    return gridToolbarItem.selected;
  }

  handleGridToolbarItemEvent(
    gridToolbarItem: GridToolBarItem,
    event?: Event | number,
  ): void {
    if (gridToolbarItem.callback) {
      gridToolbarItem.callback(event);
    }
    this.kendoToolbar.popupRef?.close();
  }

  checkIfMultipleSelectionMode(): boolean {
    if (this.selectable && typeof this.selectable !== 'boolean') {
      return this.selectable.mode === 'multiple';
    } else {
      return false;
    }
  }

  getDropdownItemsTextAndTitleTranslations(
    gridToolBarItems: GridToolBarItem[],
  ): GridToolBarItem[] {
    return gridToolBarItems.map((item) => {
      return {
        ...item,
        text: this.translateService.instant(this.getToolbarItemText(item)),
        title: this.translateService.instant(this.getToolbarItemTitle(item)),
      };
    });
  }

  onModifyGeometryToolbarButtonClick(
    payload: GenericToolbarModifyGeometryPayload,
  ): void {
    const wktGeom = payload.wktGeom;

    if (!wktGeom) {
      this.toastr.warning(this.translateService.instant('GK.MAP.NO_RANGE'));
      return;
    }

    this.showGeomOnMap(wktGeom);

    this.gkKendoGridMapService.$pendingMapActions.next([
      new MapAction(MapObjectTableActionType.Edit, {
        geom: wktGeom,
      }),
    ]);
  }

  onShowGeometryToolbarButtonClick(
    payload: GenericToolbarShowGeometryPayload,
  ): void {
    const wktGeom = payload.wktGeom;

    if (!wktGeom) {
      this.toastr.warning(this.translateService.instant('GK.MAP.NO_RANGE'));
      return;
    }

    this.showGeomOnMap(wktGeom);
  }
}
