import { DepartmentCode } from '@gk/gk-modules';

const DESIGNER_PORTAL_ID = 9;
const PRZP_PORTAL_ID = 10;

export class PortalSettingsAccess {
  constructor(
    public przpAccess: boolean,
    public designerAccess: boolean,
  ) {}

  static getPortalSettingsAccess(webPortal: WebPortal[]): PortalSettingsAccess {
    return new this(
      webPortal.some((portal) => portal.portalId === PRZP_PORTAL_ID),
      webPortal.some((portal) => portal.portalId === DESIGNER_PORTAL_ID),
    );
  }
}

export interface TranslationVisibilityPortal {
  isVisibleGr: boolean;
  isVisibleEn: boolean;
  isVisibleCz: boolean;
  isVisibleTranslate: boolean;
}

export interface WebPortalResponseDto {
  PortalId: number;
  WydzialId?: number;
  Name: string;
  NameEn: string;
  NameDe: string;
  NameCs: string;
  Pokaz: boolean;
  Description: string;
  DescriptionEn: string;
  DescriptionDe: string;
  DescriptionCs: string;
  Url: string;
  ApiUrl: string;
  Type: string;
  LogoUrl: string;
  Color: string;
  LogowanieEwid: boolean;
  LogowaniePz: boolean;
  MasterId: number;
  KodWydzialu: DepartmentCode;
  Wkt: string;
  Ewniosek: boolean;
}

export class WebPortal {
  constructor(
    public portalId: number,
    public departmentId: number,
    public name: string,
    public nameEn: string,
    public nameDe: string,
    public nameCz: string,
    public show: boolean,
    public description: string,
    public descriptionEn: string,
    public descriptionDe: string,
    public descriptionCs: string,
    public url: string,
    public apiUrl: string,
    public type: string,
    public logoUrl: string,
    public color: string,
    public ewidLogging: boolean,
    public pzLogging: boolean,
    public masterId: number,
    public departmentCode: DepartmentCode,
    public wkt: string,
    public eRequest: boolean,
  ) {}
  static fromApiToApp(webPortalResponseDto: WebPortalResponseDto): WebPortal {
    return new this(
      webPortalResponseDto.PortalId,
      webPortalResponseDto.WydzialId,
      webPortalResponseDto.Name,
      webPortalResponseDto.NameEn,
      webPortalResponseDto.NameDe,
      webPortalResponseDto.NameCs,
      webPortalResponseDto.Pokaz,
      webPortalResponseDto.Description,
      webPortalResponseDto.DescriptionEn,
      webPortalResponseDto.DescriptionDe,
      webPortalResponseDto.DescriptionCs,
      webPortalResponseDto.Url,
      webPortalResponseDto.ApiUrl,
      webPortalResponseDto.Type,
      webPortalResponseDto.LogoUrl,
      webPortalResponseDto.Color,
      webPortalResponseDto.LogowanieEwid,
      webPortalResponseDto.LogowaniePz,
      webPortalResponseDto.MasterId,
      webPortalResponseDto.KodWydzialu,
      webPortalResponseDto.Wkt,
      webPortalResponseDto.Ewniosek,
    );
  }
}
