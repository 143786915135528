import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { InputToolBarItemData } from '../../gk-kendo-grid.model';
import { SVGIcon } from '@progress/kendo-svg-icons';

@Component({
  providers: [
    {
      provide: ToolBarToolComponent,
      useExisting: forwardRef(() => GkToolbarButtonWithInputComponent),
    },
  ],
  selector: 'gk-kendo-toolbar-button-with-input',
  template: `
    <ng-template #toolbarTemplate>
      <div class="input-group">
        <button
          id="toolbarButton"
          #toolbarButton
          [tabindex]="tabIndex"
          type="button"
          kendoButton
          size="small"
          [svgIcon]="svgIcon"
          [attr.title]="svgIcon ? text : ''"
          (click)="onClick()"
        >
          @if (!svgIcon) {
            {{ text }}
          }
        </button>
        <kendo-numerictextbox
          [spinners]="false"
          [style.width]="inputData.width"
          [autoCorrect]="true"
          [min]="inputData.min"
          [max]="inputData.max!"
          [value]="inputData.value"
          (valueChange)="onValueChange($event)"
          format="n0"
          aria-labelledby="toolbarButton"
          size="small"
        >
        </kendo-numerictextbox>
        @if (inputData.suffixValue) {
          <span class="input-group-text p-0">{{ inputData.suffixValue }}</span>
        }
      </div>
    </ng-template>
  `,
})
export class GkToolbarButtonWithInputComponent extends ToolBarToolComponent {
  @ViewChild('toolbarTemplate', { static: true })
  public override toolbarTemplate: TemplateRef<any>;
  @Input() text: string;
  @Input() svgIcon: SVGIcon;

  @Input() inputData: InputToolBarItemData;
  @Output() clickEvent = new EventEmitter<number>();
  constructor() {
    super();
  }

  public onClick(): void {
    this.clickEvent.emit(this.inputData.value);
  }

  public onValueChange(newValue: number): void {
    this.inputData.value = newValue;
  }
}
