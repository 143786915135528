import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import { map, Observable, shareReplay } from 'rxjs';
import { DocumentSection, DocumentSectionApi } from './document-section.model';

@Injectable()
export class DocumentSectionService {
  private documentSectionsMap = new Map<
    PortalId,
    Observable<DocumentSection[]>
  >();

  constructor(private httpClient: HttpClient) {}

  get(portalId: PortalId): Observable<DocumentSection[]> {
    let documentSections$ = this.documentSectionsMap.get(portalId);

    if (!documentSections$) {
      documentSections$ = this.httpClient
        .get<DocumentSectionApi[]>(`/api/eod/kdokrodz/portal/${portalId}/pliki`)
        .pipe(
          map((documentSectionsApi) =>
            documentSectionsApi.map((documentSectionApi) =>
              DocumentSection.fromApiToApp(documentSectionApi),
            ),
          ),
          shareReplay(1),
        );
      this.documentSectionsMap.set(portalId, documentSections$);
    }

    return documentSections$;
  }

  downloadSampleDocument(
    sampleDocumentId: string | number,
  ): Observable<HttpResponse<Blob>> {
    const body = { Id: sampleDocumentId };
    return this.httpClient.post('/api/system/web/plik/download', body, {
      responseType: 'blob',
      observe: 'response',
    });
  }
}
