import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ApiResponse,
  getDefaultControlValue,
  LawPersonAddressControlName,
  LawPersonControlName,
  LawPersonType,
  LegalPersonControlName,
  NaturalPersonControlName,
  nipValidator,
  ValidatePostalCode,
} from '@gk/gk-modules';
import { map, Observable, shareReplay } from 'rxjs';
import {
  FSPNewRequestControlName,
  FSPNewRequestFormGroup,
  LegalInterest,
  LegalInterestDto,
} from './new-request-form.model';

@Injectable()
export class FSPNewRequestFormService {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
  ) {}

  getFormGroup(): FormGroup<FSPNewRequestFormGroup> {
    return this.formBuilder.group({
      [FSPNewRequestControlName.LawPerson]: this.getLawPersonFormGroup(),
      [FSPNewRequestControlName.Purpose]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FSPNewRequestControlName.Period]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FSPNewRequestControlName.PublicRegister]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FSPNewRequestControlName.PersonalData]: [true],
      [FSPNewRequestControlName.Par1]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FSPNewRequestControlName.Par2]: [
        getDefaultControlValue(),
        [Validators.required],
      ],
      [FSPNewRequestControlName.Remarks]: [getDefaultControlValue()],
    });
  }

  getLawPersonFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      [LawPersonControlName.Type]: LawPersonType.Natural,
      [LawPersonControlName.NaturalPersonData]: this.formBuilder.group({
        [NaturalPersonControlName.FirstName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [NaturalPersonControlName.LastName]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
      }),
      [LawPersonControlName.LegalPersonData]: this.formBuilder.group({
        [LegalPersonControlName.Nip]: [
          getDefaultControlValue(),
          [Validators.required, nipValidator()],
        ],
      }),
      [LawPersonControlName.ApplicantFromRegonAddress]: this.formBuilder.group({
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.StreetAbsence]: false,
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(),
          [Validators.required],
        ],
        [LawPersonAddressControlName.PostOffice]: [getDefaultControlValue()],
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [getDefaultControlValue()],
      }),
      [LawPersonControlName.Address]: this.formBuilder.group({
        [LawPersonAddressControlName.Place]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [LawPersonAddressControlName.Street]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [LawPersonAddressControlName.BuildingNumber]: [
          getDefaultControlValue(true),
          Validators.required,
        ],
        [LawPersonAddressControlName.LocalNumber]: [
          getDefaultControlValue(true),
        ],
        [LawPersonAddressControlName.PostalCode]: [
          getDefaultControlValue(true),
          [Validators.required, ValidatePostalCode],
        ],
      }),
    });
  }

  getLegalInterests(): Observable<LegalInterest[]> {
    return this.httpClient
      .get<
        ApiResponse<LegalInterestDto[]>
      >('/api/interesant/przp/slo/podstRpt/prawna')
      .pipe(
        map((response) => response.Response),
        map((legalInterests) =>
          legalInterests.map((legalInterest) =>
            LegalInterest.fromApiToApp(legalInterest),
          ),
        ),
        shareReplay(1),
      );
  }
}
