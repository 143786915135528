import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  ErrorEvent,
  FileListSingleItemComponent,
  FileState,
  SelectEvent,
} from '@progress/kendo-angular-upload';

import {
  GkKendoSuccessEvent,
  GkKendoUploadData,
  GkKendoUploadEvent,
  GkKendoUploadFileInfo,
  NewDokumentPowiazanyRequestDto,
} from './gk-kendo-upload.model';
import { UploadDocType } from './gk-kendo-upload-doc-type-dictionary/gk-kendo-upload-doc-type-dictionary.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'gk-gk-kendo-upload',
  templateUrl: './gk-kendo-upload.component.html',
  styleUrls: ['./gk-kendo-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GkKendoUploadComponent implements OnInit {
  @ViewChildren('typeOfDoc') set content(
    dropDownListComponents: QueryList<DropDownListComponent>,
  ) {
    if (dropDownListComponents.length) {
      this.renderer.listen(
        dropDownListComponents.get(dropDownListComponents.length - 1).wrapper
          .nativeElement,
        'click',
        (event: Event) => {
          event.stopPropagation();
        },
      );
    }
  }
  uploadSaveUrl: string = undefined;
  uploadData: GkKendoUploadData = undefined;
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  onUploadSuccess: (uploadEvent: GkKendoSuccessEvent) => void;
  onUploadError: (uploadEvent: ErrorEvent) => void;
  public typeOfDocs: UploadDocType[] = [];
  public fileListSingleItemComponent = FileListSingleItemComponent;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private deviceService: DeviceDetectorService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    if (this.deviceService.isMobile()) {
      this.hideDropZone();
      this.setProperUploadFilesContainerHeight();
    }
  }

  hideDropZone(): void {
    const dropZoneElement =
      this.elementRef.nativeElement.querySelector('.k-dropzone-hint');
    if (dropZoneElement) {
      this.renderer.setStyle(dropZoneElement, 'display', 'none');
    }
  }

  setProperUploadFilesContainerHeight(): void {
    const kUploadFilesElement =
      this.elementRef.nativeElement.querySelector('.k-upload-files');
    if (kUploadFilesElement) {
      this.renderer.setStyle(kUploadFilesElement, 'height', '83vh');
    }
  }

  public success(e: GkKendoSuccessEvent): void {
    console.log(`success event ${e.files[0].name}`);
    if (
      typeof this.onUploadSuccess === 'function' &&
      e.operation === 'upload'
    ) {
      this.onUploadSuccess(e);
    }
  }
  public error(e: ErrorEvent): void {
    console.log(`error event: ${e.files[0].name}`);
    if (typeof this.onUploadError === 'function') {
      this.onUploadError(e);
    }
  }

  public onSelect(e: SelectEvent): void {
    // const that = this;
    const fileNames: string[] = [];

    e.files.forEach((file) => {
      fileNames.push(file.name);

      if (!file.validationErrors) {
        console.log(`valid file select event: ${fileNames}`);
      }
    });

    console.log(`file select event: ${fileNames}`);
  }

  matchFileToTypeOfDoc(file: GkKendoUploadFileInfo): UploadDocType {
    if (this.typeOfDocs.length === 1) {
      file.typeOfDoc = this.typeOfDocs[0];

      return file.typeOfDoc;
    }

    const matchingType = this.typeOfDocs.find((element) =>
      new RegExp(`^${element.regexPattern}$`, 'gi').test(file.name),
    );
    file.typeOfDoc = matchingType || file.typeOfDoc;

    return file.typeOfDoc;
  }

  uploadEventHandler(e: GkKendoUploadEvent): void {
    const file = e.files[0];
    e.data = {
      ...{
        NazwaPliku: e.files[0].name,
        RodzajOpracowaniaDokumentu: file?.typeOfDoc?.id,
        Opis: file?.description ?? '',
      },
      ...this.uploadData,
    } as NewDokumentPowiazanyRequestDto;
  }

  public fileStatusText(
    file: GkKendoUploadFileInfo,
    fileState: FileState,
  ): string {
    const isPresent = (value: any): boolean =>
      value !== null && value !== undefined;
    const errors: string[] = file.validationErrors;

    if (fileState === FileState.Uploaded) {
      return `${this.messageService.get('kendo.upload.fileStatusUploaded')}`;
    }

    if (file.state === FileState.Failed) {
      return `${this.messageService.get('kendo.upload.fileStatusFailed')}`;
    }

    if (!isPresent(errors)) {
      return this.fileListSingleItemComponent.prototype.getTotalFilesSizeMessage(
        [file],
      );
    }

    return this.fileListSingleItemComponent.prototype.getFileValidationMessage(
      file,
    );
  }

  public isUploadSuccessful(state: FileState): boolean {
    return state === FileState.Uploaded;
  }

  public isUploadFailed(state: FileState): boolean {
    return state === FileState.Failed;
  }

  public get isNotYetUploaded(): boolean {
    return !this.isUploadFailed && !this.isUploadSuccessful;
  }
}
