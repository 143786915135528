import { Community } from '../../../gk-dynamic-form/services/cached/cached.model';

export enum PprfTransactionAndPricingFormControlName {
  RegistrationDateFrom = 'registrationDateFrom',
  RegistrationDateTo = 'registrationDateTo',
  TransactionAndPricingDateFrom = 'transactionAndPricingDateFrom',
  TransactionAndPricingDateTo = 'transactionAndPricingDateTo',
  Type = 'type',
  TransactionType = 'transactionType',
  MarketType = 'marketType',
  CadastralUnit = 'cadastralUnit',
  District = 'district',
  ValuationPurpose = 'valuationPurpose',
  Seller = 'seller',
  Buyer = 'buyer',
  HasDigitalDocumentation = 'hasDigitalDocumentation',
  HasPrice = 'hasPrice',
  GrossTransactionPrice = 'grossTransactionPrice',
}

export class PprfTransactionAndPricingFormModel {
  constructor(
    public registrationDateFrom: string,
    public registrationDateTo: string,
    public transactionAndPricingDateFrom: string,
    public transactionAndPricingDateTo: string,
    public type: number[],
    public transactionType: number[],
    public marketType: number[],
    public cadastralUnit: Community[],
    public district: number[],
    public valuationPurpose: number[],
    public seller: number[],
    public buyer: number[],
    public hasDigitalDocumentation: boolean,
    public hasPrice: boolean,
    public grossTransactionPrice: number[],
  ) {}

  static getEmptyModel(): PprfTransactionAndPricingFormModel {
    return new this(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }
}

export const pprfTransactionAndPricingFormModel =
  PprfTransactionAndPricingFormModel.getEmptyModel();
