@if (documentSections?.length) {
  <section class="px-4 pt-4 border rounded mb-4" [ngClass]="borderClass">
    <h2 class="mb-4">{{ 'ATTACHMENTS' | translate }}</h2>
    @for (documentSection of documentSections; track documentSection.id) {
      <section>
        <div
          class="card mb-4"
          [ngClass]="
            shouldShowFileInputInvalidMessage(documentSection)
              ? 'border-danger border-2'
              : borderClass
          "
        >
          <div class="card-header text-white" [ngClass]="backgroundClass">
            {{ documentSection.name | translate }}
          </div>
          <div
            class="card-body"
            [ngClass]="{
              required:
                documentSection.isObligatory &&
                documentSection.alternativeDocumentTypes?.length
            }"
          >
            @if (
              documentSection.uploadAvailable &&
              !documentSection.alternativeDocumentTypes?.length
            ) {
              <gk-file-input
                class="mb-2 bg-color-from-db-for-button-child"
                [ngClass]="{ required: documentSection.isObligatory }"
                [attr.aria-required]="documentSection.isObligatory"
                [showPreviews]="true"
                (filesChange)="
                  handleFileInputAction(documentSection.id, $event)
                "
              >
              </gk-file-input>
            }
            @if (
              documentSection.uploadAvailable &&
              documentSection.alternativeDocumentTypes?.length
            ) {
              @for (
                alternativeDocumentType of documentSection.alternativeDocumentTypes;
                track alternativeDocumentType.id
              ) {
                <div class="mb-2 alternative-document-type">
                  <span>{{ alternativeDocumentType.name | translate }}</span>
                  <gk-file-input
                    class="mb-2 bg-color-from-db-for-button-child"
                    [showPreviews]="true"
                    (filesChange)="
                      handleFileInputAction(alternativeDocumentType.id, $event)
                    "
                  ></gk-file-input>
                </div>
              }
            }
            @if (documentSection.sampleDocuments?.length) {
              @for (
                sampleDocument of documentSection.sampleDocuments;
                track sampleDocument.id
              ) {
                <button
                  type="button"
                  class="btn btn-outline-color-from-db mt-2 me-1"
                  (click)="downloadSampleDocument(sampleDocument.id)"
                >
                  <i class="fa fa-download"></i>
                  <span class="ms-1">{{ sampleDocument.displayName }}</span>
                </button>
              }
            }
          </div>

          @if (documentSection.alternativeDocumentTypes?.length) {
            <div class="card-footer">
              <div
                class="mb-0"
                [ngClass]="
                  shouldShowFileInputInvalidMessage(documentSection)
                    ? 'alert alert-danger'
                    : 'text-danger'
                "
              >
                {{ 'AT_LEAST_ONE_ATTACHMENT_REQUIRED' | translate }}
              </div>
            </div>
          } @else if (shouldShowFileInputInvalidMessage(documentSection)) {
            <div class="card-footer">
              <div class="alert alert-danger mb-0">
                {{ 'NO_FILE_CHOSEN' | translate }}
              </div>
            </div>
          }
        </div>
      </section>
    }
  </section>
}
