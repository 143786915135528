<div class="container-grid-1fr-auto">
  <kendo-grid
    [(selectedKeys)]="selectedKeys"
    [autoBind]="autoBind"
    [class]="'overflow-auto'"
    [filterable]="filterable"
    [firstRowSelected]="firstRowSelected"
    [gkKendoGridBinding]="gridDataService"
    [kendoGridSelectBy]="$any(selectionKey)"
    [loading]="gridDataService?.$loading | async"
    [navigable]="navigable"
    [pageSize]="pageSize"
    [pageable]="pageable"
    [resizable]="resizable"
    [rowClass]="rowCallback"
    [rowHeight]="getRowHeight()!"
    [scrollable]="scrollable"
    [selectable]="selectable"
    [serverPagingSorting]="serverPagingSorting"
    [size]="'small'"
    [sortable]="sortable"
    [style.height]="height"
    [style.width]="width"
    [unionResponseWithGridData]="unionResponseWithGridData"
  >
    @if (handleGridToolbarPosition()) {
      <ng-template
        kendoGridToolbarTemplate
        [position]="handleGridToolbarPosition()!"
        let-position="position"
      >
        @if (position === 'top' && topGridToolbarItems.length) {
          <gk-kendo-grid-toolbar
            [selectable]="selectable"
            [gridToolbarItems]="topGridToolbarItems"
            [style.width.%]="100"
            [parent]="this"
            [position]="position"
            [overflow]="true"
          ></gk-kendo-grid-toolbar>
        }
        @if (position === 'bottom' && bottomGridToolbarItems.length) {
          <gk-kendo-grid-toolbar
            [selectable]="selectable"
            [gridToolbarItems]="bottomGridToolbarItems"
            [style.width.%]="100"
            [parent]="this"
            [position]="position"
          ></gk-kendo-grid-toolbar>
        }
      </ng-template>
    }

    @if (checkIfMultipleSelectionMode()) {
      <kendo-grid-checkbox-column
        [showSelectAll]="false"
        [width]="20"
      ></kendo-grid-checkbox-column>
    }

    @if (rowIndexColumn) {
      <kendo-grid-column [width]="30" title="#">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          {{ rowIndex + 1 }}
        </ng-template>
      </kendo-grid-column>
    }

    @for (column of columns; track column.title) {
      <kendo-grid-column
        field="{{ column.field }}"
        title="{{ column.title | translate }}"
        format="{{ column.format }}"
        [filter]="column.type"
        [width]="getColumnWidth(column.width, column.type)"
        [hidden]="isColumnHidden(column.field) | async"
        [headerStyle]="{
          'justify-content': 'center',
          'vertical-align': 'middle',
          'text-align': 'center',
          'white-space': 'normal'
        }"
      >
        <ng-container>
          <ng-template kendoGridCellTemplate let-dataItem>
            @switch (column.type) {
              @case (columnType.Boolean) {
                <ng-container
                  [ngTemplateOutlet]="kendoGridCellBooleanTemplate"
                  [ngTemplateOutletContext]="{ dataItem, column }"
                ></ng-container>
              }
              @case (columnType.Date) {
                <ng-container
                  [ngTemplateOutlet]="kendoGridCellUiDateTemplate"
                  [ngTemplateOutletContext]="{ dataItem, column }"
                ></ng-container>
              }
              <!-- https://www.telerik.com/kendo-angular-ui/components/grid/accessories/tooltips/-->
              @case (columnType.Text) {
                <span
                  kendoTooltip
                  class="top"
                  position="top"
                  title="{{ dataItem[column.field] }}"
                >
                  {{
                    column.cellTemplate
                      ? column.cellTemplate(dataItem[column.field], dataItem)
                      : dataItem[column.field]
                  }}
                </span>
              }
            }
          </ng-template>
        </ng-container>
      </kendo-grid-column>
    }
    @if (footerConfig) {
      <ng-template kendoGridStatusBarTemplate>
        <gk-kendo-grid-footer
          [footerConfig]="footerConfig"
          [counter]="gridDataService?.$count"
          class="flex-grow-1"
        ></gk-kendo-grid-footer>
      </ng-template>
    }
    <ng-template kendoGridLoadingTemplate>
      <gk-loading-panel
        [abortConfig]="abortConfig"
        [abortRequest]="abortRequest"
        [isLoading]="gridDataService?.$loading | async"
      ></gk-loading-panel>
    </ng-template>
  </kendo-grid>
</div>
<ng-template
  #kendoGridCellBooleanTemplate
  let-column="column"
  let-dataItem="dataItem"
>
  {{ dataItem[column.field] ? 'Tak' : 'Nie' }}
</ng-template>

<ng-template
  #kendoGridCellUiDateTemplate
  let-column="column"
  let-dataItem="dataItem"
>
  {{ dataItem[column.field] | date: column.format || 'yyyy-MM-dd' }}
</ng-template>
