<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="mt-5 mb-3">
      {{
        'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.TITLE'
          | translate
      }}
    </h2>

    <br />

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.TITLE'
            | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkProxyDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.ProxyDetailsCheckbox"
            [formControlName]="controlName.ProxyDetailsCheckbox"
          />

          <label
            class="form-check-label checkbox"
            [attr.for]="controlName.ProxyDetailsCheckbox"
          >
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.PROXY_DETAILS'
                | translate
            }}
          </label>
        </div>
      </div>

      @if (proxyDetailsVisible) {
        <div class="card-body">
          <app-investor-details
            [legalPersonEnabled]="false"
            [investorDetailsFromGroup]="getProxyDetailsFormGroup()"
            [automaticCopyingDataFromPz]="false"
          ></app-investor-details>
        </div>
      }
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkParcelsAndExclusionAreasValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.PARCELS_AND_EXCLUSION_AREA'
            | translate
        }}
      </div>

      <div class="card-body">
        <ul
          ngbNav
          #parcelsAndRangesTabs="ngbNav"
          class="nav-pills"
          [activeId]="parcelTabId.NoContent"
        >
          <li [ngbNavItem]="parcelTabId.Form" [id]="parcelTabId.Form">
            <a ngbNavLink>
              {{
                'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.SEARCH_BY_ATTRIBUTES'
                  | translate
              }}
            </a>

            <ng-template ngbNavContent>
              <app-advanced-parcel-search-form
                (chosenParcels)="addUniqueParcelsToList($event)"
              ></app-advanced-parcel-search-form>
            </ng-template>
          </li>

          <li [ngbNavItem]="parcelTabId.Map" [id]="parcelTabId.Map">
            <a ngbNavLink>
              {{
                'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.CHOOSE_FROM_MAP'
                  | translate
              }}
            </a>

            <ng-template ngbNavContent>
              <div class="mt-2">
                <gk-map
                  [mapState]="mapState"
                  (dispatch)="handleMapAction($event)"
                ></gk-map>
              </div>
            </ng-template>
          </li>

          <li [ngbNavItem]="parcelTabId.NoContent" [id]="parcelTabId.NoContent">
            <a ngbNavLink>
              {{
                'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.HIDE_SEARCHING'
                  | translate
              }}
            </a>

            <ng-template ngbNavContent> </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="parcelsAndRangesTabs"></div>
      </div>

      @if (
        (submitted && !areParcelsChosen()) ||
        (submitted && !areExclusionAreasChosen) ||
        areMapObjectsChosen()
      ) {
        <div class="card-footer">
          <h4 [hidden]="!areMapObjectsChosen()">
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.CHOSEN_PARCELS_AND_EXCLUSION_AREA'
                | translate
            }}
          </h4>
          @if (mapState.mapObjectTablesState[0].mapObjects.length) {
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }
          @if (submitted && !areParcelsChosen()) {
            <div class="alert alert-danger mt-2">
              {{
                'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.PARCELS_REQUIRED'
                  | translate
              }}
            </div>
          }
          @if (submitted && !areExclusionAreasChosen()) {
            <div class="alert alert-danger mt-2">
              {{
                'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.EXCLUSION_AREA_REQUIRED'
                  | translate
              }}
            </div>
          }
        </div>
      }
    </section>

    <div class="row row-cols-1 row-cols-md-2">
      <div class="col">
        <section
          class="card mb-4"
          [ngClass]="
            checkExclusionPurposeFormControlValidAndFormValidated()
              ? 'border-danger border-2'
              : 'border-dark'
          "
        >
          <label
            class="card-header text-white bg-dark"
            [attr.for]="controlName.ExclusionPurpose"
          >
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.EXCLUSION_PURPOSE'
                | translate
            }}
          </label>

          <div class="card-body">
            <select
              [name]="controlName.ExclusionPurpose"
              [id]="controlName.ExclusionPurpose"
              [formControlName]="controlName.ExclusionPurpose"
              class="form-select form-select-sm required"
              aria-required="true"
            >
              <option [ngValue]="null" hidden selected>
                {{
                  'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.PLACEHOLDERS.SELECT'
                    | translate
                }}
              </option>

              @for (
                exclusionPurpose of exclusionPurposes;
                track exclusionPurpose.id
              ) {
                <option [ngValue]="exclusionPurpose.id">
                  {{ exclusionPurpose.name }}
                </option>
              }
            </select>
          </div>
        </section>
      </div>

      <div class="col">
        <section
          class="card mb-4"
          [ngClass]="
            checkInvestmentCommencementDateFormControlValidAndFormValidated()
              ? 'border-danger border-2'
              : 'border-dark'
          "
        >
          <label
            class="card-header text-white bg-dark"
            [attr.for]="controlName.InvestmentCommencementDate"
          >
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.INVESTMENT_COMMENCEMENT_DATE'
                | translate
            }}
          </label>

          <div class="card-body">
            <div class="input-group">
              <input
                class="form-control form-control-sm required"
                aria-required="true"
                [attr.placeholder]="
                  'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate
                "
                [id]="controlName.InvestmentCommencementDate"
                [name]="controlName.InvestmentCommencementDate"
                [formControlName]="controlName.InvestmentCommencementDate"
                ngbDatepicker
                #d="ngbDatepicker"
              />

              <button
                class="btn btn-outline-secondary fa fa-calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </section>
      </div>
    </div>

    <app-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetailsVisible"
    >
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.DECISION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{
              'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
      </section>
    }

    <section class="my-2">
      <app-cautions
        [portalId]="portalId"
        [formGroup]="formGroup"
        [submitted]="submitted"
      ></app-cautions>
    </section>

    <button type="submit" class="btn btn-primary">
      {{
        'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.AGRICULTURAL_LAND_USE_REQUEST.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <div class="d-flex flex-column">
      @if (docSignMsgTxt) {
        <span [ngClass]="'text-' + docSignMsgType">
          {{ docSignMsgTxt }}
        </span>
      }

      @if (docSignUrl) {
        <span class="my-2 text-info">
          {{ docSignTranslations['SIGN_INFO'] }}
        </span>
      }

      @if (docSignUrl) {
        <a class="link" [href]="docSignUrl" target="_blank">
          {{ docSignTranslations['SIGN'] }}
        </a>
      }

      @if (docSignPending) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>
