import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  GkComponentsModule,
  GkDynamicListModule,
  GkMapModule,
  GkPaymentsModule,
  GkPersonSearchModule,
  GkValidatorsModule,
  NumberCellPipe,
} from '@gk/gk-modules';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AccessibilityStatementWrapperComponent } from '../components/accessibility-statement-wrapper/accessibility-statement-component-wrapper';
import { AttachmentsComponent } from '../components/attachments/attachments.component';
import { ElectronicCorrespondenceStatementComponent } from '../components/electronic-correspondence-statement/electronic-correspondence-statement.component';
import { PageNotFoundComponent } from '../components/page-not-found/page-not-found.component';
import { RequestCancelButtonComponent } from '../components/request-cancel-button/request-cancel-button.component';
import { DistrictListComponent } from '../modules/free-services-portal/district-list/district-list.component';
import { DistrictMultiSelectComponent } from '../modules/free-services-portal/district-multi-select/district-multi-select.component';
import { AddressListComponent } from './address-list/address-list.component';
import { AddressSearchFormComponent } from './address-search-form/address-search-form.component';
import { AdvancedParcelSearchFormComponent } from './advanced-parcel-search-form/advanced-parcel-search-form.component';
import { AttachmentInputComponent } from './attachment-input/attachment-input.component';
import { AuthorizedPersonToRegistryViewFormComponent } from './authorized-person-to-registry-view/authorized-person-to-registry-view-form/authorized-person-to-registry-view-form.component';
import { AuthorizedPersonToRegistryViewListComponent } from './authorized-person-to-registry-view/authorized-person-to-registry-view-list/authorized-person-to-registry-view-list.component';
import { AuthorizedPersonToRegistryViewComponent } from './authorized-person-to-registry-view/authorized-person-to-registry-view.component';
import { AuthorizedPersonComponent } from './authorized-person/authorized-person.component';
import { AcceptCautionsComponent } from './cautions/accept-cautions/accept-cautions.component';
import { CautionsComponent } from './cautions/cautions.component';
import { ClosedAreasTableComponent } from './closed-areas-table/closed-areas-table.component';
import { ConnectionsAndNetworksTableComponent } from './connections-and-networks-table/connections-and-networks-table.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { LetDirective } from './directives/let/let.directive';
import { SessionRefresherDirective } from './directives/session-refresher/session-refresher.directive';
import { EnablementComponent } from './enablement/enablement.component';
import { FeeWaiverComponent } from './fee-waiver/fee-waiver.component';
import { FormLegendComponent } from './form-legend/form-legend.component';
import { HeaderComponent } from './header/header.component';
import { InvestorDetailsComponent } from './investor-details/investor-details.component';
import { MainRequestsListComponent } from './main-requests-list/main-requests-list.component';
import { ModalProlongSessionComponent } from './modal-prolong-session/modal-prolong-session.component';
import { ParcelListComponent } from './parcel-list/parcel-list.component';
import { ParcelSearchFormComponent } from './parcel-search-form/parcel-search-form.component';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { PermissionNumberModalComponent } from './permission-number-modal/permission-number-modal.component';
import { SecondHeaderComponent } from './second-header/second-header.component';
import { ShippingFormComponent } from './shipping-form/shipping-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    GkValidatorsModule,
    NgbModule,
    TranslateModule,
    RouterModule,
    GkMapModule,
    GkDynamicListModule,
    GkPaymentsModule,
    GkComponentsModule,
    GkPersonSearchModule,
  ],
  declarations: [
    PageNotFoundComponent,
    HeaderComponent,
    SecondHeaderComponent,
    ParcelListComponent,
    AddressListComponent,
    ParcelSearchFormComponent,
    AddressSearchFormComponent,
    AttachmentInputComponent,
    AdvancedParcelSearchFormComponent,
    DateRangePickerComponent,
    ModalProlongSessionComponent,
    ConnectionsAndNetworksTableComponent,
    FormLegendComponent,
    SessionRefresherDirective,
    SessionRefresherDirective,
    ClosedAreasTableComponent,
    DistrictMultiSelectComponent,
    DistrictListComponent,
    ClosedAreasTableComponent,
    PaymentsListComponent,
    InvestorDetailsComponent,
    ShippingFormComponent,
    MainRequestsListComponent,
    EnablementComponent,
    AuthorizedPersonComponent,
    FeeWaiverComponent,
    AuthorizedPersonToRegistryViewFormComponent,
    AuthorizedPersonToRegistryViewListComponent,
    AuthorizedPersonToRegistryViewComponent,
    PermissionNumberModalComponent,
    LetDirective,
    CautionsComponent,
    ElectronicCorrespondenceStatementComponent,
    AcceptCautionsComponent,
    AttachmentsComponent,
    NumberCellPipe,
    AccessibilityStatementWrapperComponent,
    RequestCancelButtonComponent,
  ],
  exports: [
    GkValidatorsModule,
    GkPersonSearchModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    HeaderComponent,
    SecondHeaderComponent,
    ParcelListComponent,
    AddressListComponent,
    ParcelSearchFormComponent,
    AddressSearchFormComponent,
    AttachmentInputComponent,
    AdvancedParcelSearchFormComponent,
    AdvancedParcelSearchFormComponent,
    DateRangePickerComponent,
    ModalProlongSessionComponent,
    ConnectionsAndNetworksTableComponent,
    FormLegendComponent,
    SessionRefresherDirective,
    ClosedAreasTableComponent,
    DistrictMultiSelectComponent,
    DistrictListComponent,
    InvestorDetailsComponent,
    ShippingFormComponent,
    EnablementComponent,
    AuthorizedPersonComponent,
    FeeWaiverComponent,
    AuthorizedPersonToRegistryViewFormComponent,
    AuthorizedPersonToRegistryViewListComponent,
    AuthorizedPersonToRegistryViewComponent,
    PermissionNumberModalComponent,
    LetDirective,
    CautionsComponent,
    ElectronicCorrespondenceStatementComponent,
    AcceptCautionsComponent,
    AttachmentsComponent,
    NumberCellPipe,
    RequestCancelButtonComponent,
  ],
})
export class SharedModule {}
