import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { getFileNameFromResponseHeaders, PortalId } from '@gk/gk-modules';
import { FileSaverService } from 'ngx-filesaver';
import { takeWhile } from 'rxjs';
import { ApiNewDokumentPowiazanyDalDto } from '../../services/designer-incoming-documents/designer-incoming-documents.model';
import { DesignerIncomingDocumentsService } from '../../services/designer-incoming-documents/designer-incoming-documents.service';
import { BaseStyleClassesDirective } from '../../shared/base-style-classes/base-style-classes.directive';
import {
  ChosenDocumentSections,
  DocumentSection,
  DocumentSectionTypeId,
} from './services/document-section/document-section.model';
import { DocumentSectionService } from './services/document-section/document-section.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
})
export class AttachmentsComponent
  extends BaseStyleClassesDirective
  implements OnDestroy, OnInit, OnChanges
{
  private isAlive = true;
  documentSections: DocumentSection[];
  chosenFiles: ChosenDocumentSections = {};
  @Input() submitted: boolean;
  @Input() portalId: PortalId;
  @Input() requiredPowerOfAttorney: boolean = undefined;

  constructor(
    public documentSectionService: DocumentSectionService,
    private fileSaverService: FileSaverService,
    private incomingDocumentsService: DesignerIncomingDocumentsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.fetchDocumentSections();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['requiredPowerOfAttorney']) {
      this.updateIsObligatoryProperty();
    }
  }

  updateIsObligatoryProperty(): void {
    this.documentSections = this.documentSections?.map((documentSection) => {
      if (
        (documentSection.id === DocumentSectionTypeId.PowerOfAttorney ||
          documentSection.id ===
            DocumentSectionTypeId.PowerOfAttorneyStampDuty) &&
        documentSection.isObligatory !== this.requiredPowerOfAttorney
      ) {
        documentSection.isObligatory = this.requiredPowerOfAttorney;
      }

      return documentSection;
    });
  }

  fetchDocumentSections(): void {
    this.documentSectionService
      .get(this.portalId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => (this.documentSections = data));
  }

  shouldShowFileInputInvalidMessage(documentSection: DocumentSection): boolean {
    return (
      this.submitted && !this.areChosenObligatoryDocuments(documentSection)
    );
  }

  handleFileInputAction(id: string | number, files: File[]): void {
    this.chosenFiles[id] = files;
  }

  downloadSampleDocument(sampleDocumentId: string | number): void {
    this.documentSectionService
      .downloadSampleDocument(sampleDocumentId)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((data) => {
        const fileName = getFileNameFromResponseHeaders(data.headers);
        this.fileSaverService.save(data.body, fileName);
      });
  }

  areChosenDocuments(): boolean {
    return this.documentSections.some(
      (documentSection: DocumentSection) =>
        !!(
          this.chosenFiles[documentSection.id] &&
          this.chosenFiles[documentSection.id].length
        ),
    );
  }

  getConvertedFiles(): Promise<ApiNewDokumentPowiazanyDalDto | Error>[] {
    return Object.entries(this.chosenFiles).flatMap(([id, files]) =>
      files.map((file) =>
        this.incomingDocumentsService.docFileToDtoDocument(file, id),
      ),
    );
  }

  areDocumentsValid(): boolean {
    return this.documentSections.every((documentSection) =>
      this.areChosenObligatoryDocuments(documentSection),
    );
  }

  areChosenObligatoryDocuments(documentSection: DocumentSection): boolean {
    const files = documentSection.alternativeDocumentTypes?.length
      ? documentSection.alternativeDocumentTypes
          .map(
            (alternativeDocumentType) =>
              !!this.chosenFiles[alternativeDocumentType.id]?.length,
          )
          .filter(Boolean)
      : this.chosenFiles[documentSection.id];

    return !documentSection.isObligatory || !!(files && files.length);
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }
}
