<div class="container container-e-service-request">
  <form [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="mt-5 mb-3">
      {{ 'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.TITLE' | translate }}
    </h2>

    <br />

    <section
      class="card mb-4"
      [ngClass]="
        checkInvestorDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'EXCLUDING_LAND_FROM_AGRICULTURAL_PRODUCTION.INVESTOR_DETAILS.TITLE'
            | translate
        }}
      </div>

      <div class="card-body">
        <app-investor-details
          [investorDetailsFromGroup]="getInvestorDetailsFormGroup()"
        ></app-investor-details>
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkProxyDetailsFormGroupValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="controlName.ProxyDetailsCheckbox"
            [formControlName]="controlName.ProxyDetailsCheckbox"
          />

          <label
            class="form-check-label checkbox"
            [attr.for]="controlName.ProxyDetailsCheckbox"
          >
            {{
              'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.PROXY_DETAILS'
                | translate
            }}
          </label>
        </div>
      </div>

      @if (proxyDetailsVisible) {
        <div class="card-body">
          <app-investor-details
            [legalPersonEnabled]="false"
            [investorDetailsFromGroup]="getProxyDetailsFormGroup()"
            [automaticCopyingDataFromPz]="false"
          ></app-investor-details>
          <div class="alert alert-info mt-2" role="alert">
            {{
              'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.PROXY_DETAILS_INFO'
                | translate
            }}
          </div>
        </div>
      }
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkIdentificationDemolitionIntentValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.IDENTIFICATION_DEMOLITION_INTENT'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-xs-12 my-1">
            <label
              class="text-nowrap"
              [attr.for]="controlName.BuildingNameAndType"
            >
              {{
                'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.BUILDING_NAME_AND_TYPE'
                  | translate
              }}
            </label>

            <input
              type="text"
              [name]="controlName.BuildingNameAndType"
              [id]="controlName.BuildingNameAndType"
              class="form-control form-control-sm required"
              aria-required="true"
              [formControlName]="controlName.BuildingNameAndType"
            />

            @if (
              shouldShowErrorAlert(
                getBuildingNameAndTypeFormControl(),
                'required'
              )
            ) {
              <div class="alert alert-danger">
                {{ 'FIELD_REQUIRED' | translate }}
              </div>
            }
          </div>
        </div>

        <div class="mb-4">
          <gk-address-form
            [isRequired]="true"
            [form]="formGroup"
          ></gk-address-form>
        </div>

        <gk-map
          [mapState]="mapState"
          (dispatch)="handleMapAction($event)"
        ></gk-map>

        <div class="mt-2">
          @if (mapState.mapObjectTablesState[0].mapObjects.length) {
            <gk-map-object-table
              [mapState]="mapState"
              [mapObjectTableStateIndex]="0"
              (dispatch)="handleMapAction($event)"
            ></gk-map-object-table>
          }
        </div>
      </div>

      @if (submitted && !areParcelsChosen()) {
        <div class="card-footer">
          <div class="alert alert-danger mt-2">
            {{
              'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.PARCELS_REQUIRED'
                | translate
            }}
          </div>
        </div>
      }
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkDescriptionValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <div class="card-header text-white bg-dark">
        {{
          'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.DESCRIPTION'
            | translate
        }}
      </div>

      <div class="card-body">
        <textarea
          [name]="controlName.Description"
          [id]="controlName.Description"
          class="form-control required"
          aria-required="true"
          [formControlName]="controlName.Description"
        ></textarea>

        @if (shouldShowErrorAlert(getDescriptionFormControl(), 'required')) {
          <div class="alert alert-danger">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        }
      </div>
    </section>

    <section
      class="card mb-4"
      [ngClass]="
        checkDemolitionWorksCommencementDateFormControlValidAndFormValidated()
          ? 'border-danger border-2'
          : 'border-dark'
      "
    >
      <label
        class="card-header text-white bg-dark"
        [attr.for]="controlName.DemolitionWorksCommencementDate"
      >
        {{
          'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.DEMOLITION_WORKS_COMMENCEMENT_DATE'
            | translate
        }}
      </label>

      <div class="card-body">
        <div class="input-group">
          <input
            class="form-control form-control-sm required"
            aria-required="true"
            [attr.placeholder]="'GK.DYNAMIC_LIST.DATE_PLACEHOLDER' | translate"
            [id]="controlName.DemolitionWorksCommencementDate"
            [name]="controlName.DemolitionWorksCommencementDate"
            [formControlName]="controlName.DemolitionWorksCommencementDate"
            ngbDatepicker
            #d="ngbDatepicker"
          />

          <button
            class="btn btn-outline-secondary fa fa-calendar"
            (click)="d.toggle()"
            type="button"
          ></button>
        </div>

        <div class="alert alert-info mt-2" role="alert">
          {{
            'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.DEMOLITION_WORKS_COMMENCEMENT_DATE_INFO'
              | translate
          }}
        </div>
      </div>
    </section>

    <app-attachments
      [portalId]="portalId"
      [submitted]="submitted"
      [requiredPowerOfAttorney]="proxyDetailsVisible"
    >
    </app-attachments>

    <section class="card border-dark mb-4">
      <div class="card-header text-white bg-dark">
        {{
          'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.DECISION_FORM'
            | translate
        }}
      </div>

      <div class="card-body">
        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.NonElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.NonElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.NonElectronicForm"
          >
            {{
              'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.NON_ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>

        <div class="form-check form-check-inline">
          <input
            type="radio"
            [id]="decisionFormType.ElectronicForm"
            [attr.name]="controlName.DecisionForm"
            [formControlName]="controlName.DecisionForm"
            [value]="decisionFormType.ElectronicForm"
            class="form-check-input"
          />

          <label
            class="form-check-label"
            [attr.for]="decisionFormType.ElectronicForm"
          >
            {{
              'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.ELECTRONIC_FORM'
                | translate
            }}
          </label>
        </div>
      </div>
    </section>

    @if (portalId) {
      <section
        class="card mb-4"
        [ngClass]="
          checkShippingFormGroupValidAndFormSubmitted()
            ? 'border-danger border-2'
            : 'border-dark'
        "
      >
        <div class="card-header text-white bg-dark">
          {{
            'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.SHIPPING_FORM'
              | translate
          }}
        </div>
        <div class="card-body">
          <app-shipping-form
            [shippingFormGroup]="getShippingFormGroup()"
            [decisionForm]="decisionFormValue"
            [portalId]="portalId"
          ></app-shipping-form>
        </div>
      </section>
    }

    <section>
      <div class="card border-dark mb-4">
        <div class="card-header text-white bg-dark">
          {{
            'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.INSTRUCTION'
              | translate
          }}
        </div>

        <div class="card-body">
          <P
            [innerHTML]="
              'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.INSTRUCTION_INFO'
                | translate
            "
          >
          </P>
        </div>
      </div>
    </section>

    <button type="submit" class="btn btn-primary">
      {{
        'CONSTRUCTION_PORTAL.DEMOLITION_NOTIFICATION.FIELDS.SUBMIT_REQUEST'
          | translate
      }}
    </button>

    <div class="d-flex flex-column">
      @if (docSignMsgTxt) {
        <span [ngClass]="'text-' + docSignMsgType">
          {{ docSignMsgTxt }}
        </span>
      }

      @if (docSignUrl) {
        <span class="my-2 text-info">
          {{ docSignTranslations['SIGN_INFO'] }}
        </span>
      }

      @if (docSignUrl) {
        <a class="link" [href]="docSignUrl" target="_blank">
          {{ docSignTranslations['SIGN'] }}
        </a>
      }

      @if (docSignPending) {
        <div class="d-flex justify-content-center my-3">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-2"><app-form-legend></app-form-legend></div>
  </form>
</div>

<ng-template #canDeactivateModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'MODAL_CAN_DEACTIVATE.ALERT_TITLE' | translate }}
    </h4>
  </div>

  <div class="modal-body">
    {{ 'MODAL_CAN_DEACTIVATE.DESCRIPTION' | translate }}
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close(false)">
      {{ 'MODAL_CAN_DEACTIVATE.CANCEL' | translate }}
    </button>

    <button type="button" class="btn btn-primary" (click)="close(true)">
      {{ 'MODAL_CAN_DEACTIVATE.CONFIRM' | translate }}
    </button>
  </div>
</ng-template>
