import { ElementRef } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import { Circle, Fill, Stroke } from 'ol/style';
import Style from 'ol/style/Style';
import { ColumnHeader } from '../../gk-components/table/table.model';
import {
  AttributesFormValue,
  FieldConfig,
} from '../../gk-dynamic-form/gk-dynamic-form.model';
import { ListDataConfig } from '../../gk-dynamic-list/gk-dynamic-list.model';
import { GkMapPrintConfig } from '../../gk-map-print/models/gk-map-print-config.model';
import { ProjectionCode, TreeNode } from './map-extent-and-layers.model';
import { MapMeasurementStaticOverlay } from './map-measurement-static-overlay.model';
import {
  MapObject,
  MapObjectApiType,
  MapObjectInfo,
  OpenLayersGeometryType,
  Wkt,
} from './map-object.model';

export class MapState {
  constructor(
    public id: string,
    public viewState: ViewState,
    public toolbarState: ToolbarState,
    public toolsState: ToolsState,
    public layersState: LayersState,
    public mapObjectTablesState: MapObjectTableState[],
    public mapObjectTableStateCurrentIndex?: number,
    public clearAllObjectsOnNewObjectSearch?: boolean,
    public ignoreObjectsWithDifferentType?: boolean,
  ) {}

  static getInitialStruct(): MapState {
    return {
      id: '',
      viewState: {
        previewModeState: new MapPreviewModeState(),
      },
      toolbarState: {},
      toolsState: {},
      layersState: {},
      mapObjectTablesState: [
        {
          mapObjects: [],
        },
      ],
    } as MapState;
  }
}

export class MapPreviewModeState {
  constructor(
    public enabled = false,
    public doneCallback?: () => void,
  ) {}
}

export class ViewState {
  constructor(
    public fullNativeExtent: Extent,
    public currentNativeExtent: Extent,
    public nativeProjectionCode: ProjectionCode,
    public resolution: number,
    public extentToFitTo: Extent,
    public backScrollIntoViewRef: ElementRef,
    public isSidebarExpanded = false,
    public previewModeState: MapPreviewModeState = {
      enabled: false,
    },
  ) {}
}

export class ToolbarState {
  constructor(
    public toolTypes: ToolType[],
    public papers: Paper[],
    public mapSheetRestriction?: string,
    public mapToolContainerHorizontalPosition = HorizontalPosition.Center,
    public toolsExpandedMode = false,
  ) {}
}

export class LayersState {
  constructor(
    public wmsLayersOptions: WmsLayerOptions[],
    public treeNode: TreeNode,
  ) {}
}

export class WmsLayerOptions {
  constructor(
    public url: string,
    public baseLayers: string,
    public params: WmsLayerParams,
    public wmsLayerType: WmsLayerType,
    public projectionSource: ProjectionSource,
    public isExternal = false,
    public externalShouldBeVisible = false,
  ) {}
}

export class WmsLayerParams {
  VERSION: string;
  LAYERS: string;
  BGCOLOR: string;
  constructor(version: string, layers: string, bgcolor?: string) {
    this.VERSION = version;
    this.LAYERS = layers;
    this.BGCOLOR = bgcolor;
  }
}

export enum WmsLayerType {
  Tile = 'tile',
  Image = 'image',
}

export enum NamesFileLoadFormControls {
  GeometryFormat = 'geometryFormat',
}

export enum ProjectionSource {
  Native = 'native',
  Default = 'default',
}

export enum HorizontalPosition {
  Left = 'map-tool-container--left',
  Center = 'map-tool-container--center',
  Right = 'map-tool-container--right',
}

export enum SourceType {
  Point = 'Point',
  LineString = 'LineString',
  Polygon = 'Polygon',
  AttributesForm = 'AttributesForm',
  PrintAttributesForm = 'PrintAttributesForm',
  QuickPrint = 'QuickPrint',
  MapSheetForm = 'MapSheetForm',
  File = 'File',
  SearchByRangeFromFile = 'SearchByRangeFromFile',
  SearchByIdFromFile = 'SearchByIdFromFile',
  RangeFromAllParcels = 'RangeFromAllParcels',
  IncreaseRangeByBuffer = 'IncreaseRangeByBuffer',
  DownloadRangeFile = 'DownloadRangeFile',
  Clear = 'Clear',
}

export enum ToolType {
  LandParcel = 'LandParcel',
  Building = 'Building',
  Premises = 'Premises',
  LandUse = 'LandUse',
  ClassificationContour = 'ClassificationContour',
  District = 'District',
  AnyPolygon = 'AnyPolygon',
  RectangularExtent = 'RectangularExtent',
  Info = 'Info',
  ControlPoint = 'ControlPoint',
  BoundaryPoint = 'BoundaryPoint',
  Measurement = 'Measurement',
  Print = 'Print',
}

export type MapToolTypeKeys = keyof typeof ToolType;

export type SourceTypeKeys = keyof typeof SourceType;

export type ToolsState = { [key in MapToolTypeKeys]: ToolState };

export type ToolState = {
  isActive: boolean;
  icon: string;
  label: string;
  mapObjects: MapObject[];
  mapObjectApiType?: MapObjectApiType;
  mapGeometryStyleConfig?: MapGeometryStyleConfig;
  interactionDrawStyleConfig?: MapGeometryStyleConfig;
  isPopupVisible?: boolean;
  isPopupLoaderVisible?: boolean;
  attributesDynamicListConfig?: ListDataConfig;
  requestedMapObjectsWithAttributes?: MapObjectInfo[];
  previewedMapObjects?: MapObject[];
  buttonText?: string;
  singleObjectMode?: boolean;
  measurementStaticOverlays?: MapMeasurementStaticOverlay[];
} & { [key in SourceTypeKeys]?: SourceState };

export type SourceState =
  | BaseSourceState
  | PointState
  | LineStringState
  | PolygonState
  | AttributesFormState
  | PrintAttributesFormState
  | MapSheetFormState
  | FileState;

export interface BaseSourceState {
  isActive: boolean;
  icon: string;
  label: string;
  isTooltipVisible: boolean;
  isTooltipLoaderVisible: boolean;
  tooltipMessage: string;
  isSelectionEnabled: boolean;
  isTranslationEnabled: boolean;
  isAlertVisible: boolean;
  alert: string;
}

export interface PointState extends BaseSourceState {
  lastWkt: Wkt;
}

export interface LineStringState extends BaseSourceState {
  lastWkt: Wkt;
}

export interface PolygonState extends BaseSourceState {
  lastWkt: Wkt;
}

export interface AttributesFormState extends BaseSourceState {
  formHeader: string;
  fieldConfigs: FieldConfig[];
  formValue: AttributesFormValue;
  mapObjects: MapObject[];
  mapObjectTableState: MapObjectTableState;
  isEnabledRectangularExtentFit: boolean;
}

export interface PrintAttributesFormState extends BaseSourceState {
  config?: GkMapPrintConfig;
}

export interface MapSheetFormState extends BaseSourceState {
  hasMapToolContainer: boolean;
  formHeader: string;
  formValue: MapSheetFormValue;
  availableMapScalesDenominators: number[];
  submitButtonText: string;
}

export interface MapSheetFormValue {
  scale: Scale;
  paper: Paper;
  center: Coordinate;
}

export interface FileState extends BaseSourceState {
  lastWkt: Wkt;
  formHeader: string;
  fieldConfigs: FieldConfig[];
  mapObjects: MapObject[];
  geometryTypes: OpenLayersGeometryType[];
}

export interface RangeFromAllParcels extends BaseSourceState {
  type: SourceType.RangeFromAllParcels;
}

export interface IncreaseRangeByBuffer extends BaseSourceState {
  buttonText: string;
  type: SourceType.IncreaseRangeByBuffer;
}

export class IncreaseRangeByBufferUtils {
  static isIncreaseRangeByBuffer = (
    item: SourceState,
  ): item is IncreaseRangeByBuffer => item && 'type' in item;
}

export interface DownloadRangeFile extends BaseSourceState {
  type: SourceType.DownloadRangeFile;
  formHeader: string;
  fieldConfigs: FieldConfig[];
}

export class MapObjectTableState {
  constructor(
    public columnHeader: ColumnHeader[],
    public title?: string,
    public mapGeometryStyleConfig?: MapGeometryStyleConfig,
    public isEnabledRemoving = false,
    public isEnabledEditing = false,
    public isEnabledPreview = false,
    public relativeMaxHeight = '25',
    public mapObjects: MapObject[] = [],
    public zoomIcon = 'gk-map-icon-zoom-to-extent',
    public zoomLabel = '',
    public zoomTitle = 'GK.MAP.ZOOM_TO_SELECTED_OBJECTS',
    public selectedMapObjects: MapObject[] = [],
    public editedMapObject?: MapObject,
    public initialEditedMapObject?: MapObject,
    public isVisibleTopologyValidationLoader = false,
    public isVisibleTopologyValidationError = false,
    public mapObjectTypes?: MapObjectApiType[],
    public showInitialEmptyRequiredRow?: boolean,
    public placeAndStreetInputs = false,
    public placeInputRequired?: boolean,
    public streetInputRequired?: boolean,
    public isEnabledBuildingLocation = false,
    public isEnabledBuildingStatus = false,
    public rangeFromParcelsVisible = true,
  ) {}
}

export enum Color {
  VioletSolid = 'rgba(140, 0, 200, 1.0)',
  VioletTransparent = 'rgba(140, 0, 200, 0.2)',
  BrightBlueSolid = 'rgba(9, 236, 221, 1.0)',
  BrightBlueTransparent = 'rgba(9, 236, 221, 0.4)',
  OrangeSolid = 'rgba(255, 98, 0, 1.0)',
  OrangeTransparent = 'rgba(255, 98, 0, 0.2)',
  BlueSolid = 'rgba(86, 128, 219, 1.0)',
  BlueTransparent = 'rgba(86, 128, 219, 0.2)',
  GreenSolid = 'rgba(137, 183, 92, 1.0)',
  GreenTransparent = 'rgba(137, 183, 92, 0.2)',
  TurquoiseSolid = 'rgba(79, 179, 208, 1.0)',
  TurquoiseTransparent = 'rgba(79, 179, 208, 0.2)',
  SandSolid = 'rgba(234, 176, 65, 1.0)',
  SandTransparent = 'rgba(234, 176, 65, 0.2)',
  BlackSolid = 'rgba(0, 0, 0, 1.0)',
  BlackHalfTransparent = 'rgba(0, 0, 0, 0.5)',
  BlackSlighlyTransparent = 'rgba(0, 0, 0, 0.7)',
  RubySolid = 'rgba(204, 0, 102, 1.0)',
  RubyTransparent = 'rgba(204, 0, 102, 0.2)',
  BrownSolid = 'rgba(102, 51, 0, 1.0)',
  BrownTransparent = 'rgba(102, 51, 0, 0.2)',
  WhiteTransparent = 'rgba(255, 255, 255, 0.2)',
  WhiteSolid = 'rgba(255, 255, 255, 1.0)',
  Transparent = 'rgba(0, 0, 0, 0)',
  GeometryPreview = 'rgb(63, 81, 181)',
  GeometryDraw = '#ffcc33',
}

export class MapFeatureStyle {
  constructor(
    public fillColor: Color,
    public strokeColor: Color,
    public strokeWidth: number,
    public lineDash: number[] | null = null,
  ) {}
}

export class MapLabelOrPointStyle {
  constructor(
    public fillColor: Color,
    public strokeColor: Color,
    public strokeWidth: number,
    public radius: number,
  ) {}
}

export class MapGeometryStyleConfig {
  constructor(
    public featureStyle = new MapFeatureStyle(
      Color.BrightBlueTransparent,
      Color.BrightBlueSolid,
      2,
    ),
    public labelOrPointStyle = new MapLabelOrPointStyle(
      Color.BlueSolid,
      Color.WhiteSolid,
      1.5,
      6,
    ),
  ) {}

  toOpenLayersStyle(): Style {
    return new Style({
      fill: this.featureStyle.fillColor
        ? new Fill({
            color: this.featureStyle.fillColor,
          })
        : undefined,
      stroke: this.featureStyle.strokeColor
        ? new Stroke({
            color: this.featureStyle.strokeColor,
            lineDash: this.featureStyle.lineDash,
            width: this.featureStyle.strokeWidth,
          })
        : undefined,
      image: new Circle({
        radius: this.labelOrPointStyle.radius,
        stroke: new Stroke({
          color: this.labelOrPointStyle.strokeColor,
        }),
        fill: new Fill({
          color: this.labelOrPointStyle.fillColor,
        }),
      }),
    });
  }
}

export enum OverlayPositioning {
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  BottomRight = 'bottom-right',
  CenterLeft = 'center-left',
  CenterCenter = 'center-center',
  CenterRight = 'center-right',
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  TopRight = 'top-right',
}

export class Scale {
  constructor(
    public denominator: number,
    public name: string,
    public resolution: number,
    public zoom: number,
  ) {}
}

export enum PaperOrientation {
  Horizontal = 'GK.MAP.HORIZONTAL',
  Vertical = 'GK.MAP.VERTICAL',
}

export interface PaperFromApi {
  Orientation: 'P' | 'L';
  Name: string;
  Width: number;
  Height: number;
}

export class Paper {
  constructor(
    public orientation: PaperOrientation,
    public name: string,
    public size: [number, number],
  ) {}

  static fromApiToApp(paperFromApi: PaperFromApi): Paper {
    return new this(
      paperFromApi.Orientation === 'P'
        ? PaperOrientation.Vertical
        : PaperOrientation.Horizontal,
      paperFromApi.Name,
      [paperFromApi.Height, paperFromApi.Width],
    );
  }
}

export enum PaperOptionProperty {
  Name = 'name',
  Orientation = 'orientation',
}

export interface AttributesFormResult {
  formValue: AttributesFormValue;
  mapObjects: MapObject[];
  mapObjectTableState: MapObjectTableState;
  zoomToSelected: boolean;
}

export interface FileLoaderFormResult {
  mapObjects: MapObject[];
  zoomToSelected: boolean;
  wkt: Wkt;
}

export interface FileLoaderFormValue {
  geometryFormat: {
    value: string;
    name: string;
  };
}

export interface AttributesDynamicListResult {
  requestedMapObjectWithAttributes: MapObjectInfo;
}

export enum AddOnType {
  Table = 'Table',
}
