import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  DepartmentCode,
  PortalsPermissions,
  PortalsPermissionsService,
  SystemSession,
} from '@gk/gk-modules';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatestWith, concatMap, map, of } from 'rxjs';
import { PortalConfig } from '../../services/portal-config/portal-config.model';
import { PortalConfigService } from '../../services/portal-config/portal-config.service';
import { SessionService } from '../../services/session/session.service';
import { WebPortal } from '../../services/web-portal/web-portal.model';
import { WebPortalService } from '../../services/web-portal/web-portal.service';
import { MainRoutes } from '../guard.models';

@Injectable()
export class PortalConfigAccessGuard {
  private path: MainRoutes;

  constructor(
    private router: Router,
    private portalsPermissionsService: PortalsPermissionsService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private portalConfigService: PortalConfigService,
    private webPortalService: WebPortalService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.path = this.getRoutePath(route);

    return this.sessionService.getSystemSession().pipe(
      combineLatestWith(
        this.portalConfigService.getPortalConfByMainRoute(this.path),
      ),
      map(([sessionState, portalConfig]) => ({
        isSessionValid: this.isSessionValid(
          sessionState,
          portalConfig,
          this.path,
        ),
        portalConfig,
      })),
      concatMap(({ isSessionValid, portalConfig }) =>
        isSessionValid ? this.checkPermissions(portalConfig) : of(false),
      ),
    );
  }

  navigateToGeodesyDepartment(): void {
    this.router.navigate([`/${MainRoutes.Department}`, DepartmentCode.Geodesy]);
  }

  navigateToSignInPage(portalRoute: MainRoutes): void {
    this.router.navigateByUrl(`/${portalRoute}/${MainRoutes.SignIn}`);
  }

  showAccessWarning(portalName: string): void {
    this.toastr.warning(
      `${this.translateService.instant('NO_ACCESS_TO_PORTAL')} ${portalName}. ${this.translateService.instant('CONTACT_SYSTEM_ADMINISTRATOR')}.`,
    );
  }

  getRoutePath(route: ActivatedRouteSnapshot): MainRoutes {
    return _.get(route.url, '[0].path', '') as MainRoutes;
  }

  showToastrMessageByPortal(): void {
    this.getCurrentPortalType().subscribe((webPortal) => {
      this.showAccessWarning(webPortal.name);
    });
  }

  getCurrentPortalType(): Observable<WebPortal> {
    return this.webPortalService.getWebPortalDataByCurrentRoute(this.path);
  }

  isSessionValid(
    sessionState: SystemSession,
    portalConfig: PortalConfig,
    path: MainRoutes,
  ): boolean {
    const isAuthenticated =
      portalConfig.sessionTypes.includes(sessionState.sessionType) &&
      !sessionState.isGuest;

    if (!isAuthenticated) {
      this.navigateToSignInPage(path);
    }

    return isAuthenticated;
  }

  checkPermissions(portalConfig: PortalConfig): Observable<boolean> {
    return this.portalsPermissionsService
      .getPermissions()
      .pipe(
        map((portalsPermissions) =>
          this.checkAccess(portalConfig.accessKeys, portalsPermissions),
        ),
      );
  }

  checkAccess(
    portalAccessKeys: string[],
    portalsPermissions: PortalsPermissions,
  ): boolean {
    if (!portalAccessKeys || portalAccessKeys.length === 0) {
      return true;
    }

    const hasAccess = portalAccessKeys.every(
      (key) =>
        portalsPermissions[key as keyof typeof portalsPermissions] === true,
    );

    if (hasAccess) {
      return true;
    }

    this.navigateToGeodesyDepartment();
    this.showToastrMessageByPortal();

    return false;
  }
}
