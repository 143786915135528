<kendo-toolbar
  #kendoToolbar
  [overflow]="overflow"
  [popupSettings]="gridToolbarPopupSettings"
  [size]="'small'"
  [style.width.%]="100"
  class="flex-nowrap"
>
  @if (position === 'top' && checkIfMultipleSelectionMode()) {
    <!--            <gk-kendo-toolbar-select-all-checkbox>-->
    <!--            </gk-kendo-toolbar-select-all-checkbox>-->

    <kendo-toolbar-button
      [title]="'Zaznacz wszystko'"
      [attr.aria-label]="'Zaznacz wszystko'"
      [svgIcon]="iwIconSelectAll"
      (click)="onSelectAllGridItemsClick()"
    >
    </kendo-toolbar-button>
  }
  @for (
    gridToolbarItem of gridToolbarItems | gridToolbarItems: parent;
    track i;
    let i = $index
  ) {
    @if (
      getToolbarItemVisibleState(gridToolbarItem.visible) &&
      gridToolbarItem.spacer &&
      !deviceService.isMobile()
    ) {
      <kendo-toolbar-spacer></kendo-toolbar-spacer>
    }
    @switch (gridToolbarItem.type) {
      @case (
        getToolbarItemVisibleState(
          gridToolbarItem.visible,
          gridToolBarButtonType.Dropdown
        )
      ) {
        <kendo-toolbar-dropdownbutton
          [showText]="gridToolbarItem.showText || 'both'"
          [arrowIcon]="true"
          [data]="
            getDropdownItemsTextAndTitleTranslations(gridToolbarItem.data!)
          "
          [title]="getToolbarItemTitle(gridToolbarItem) | translate"
          [text]="getToolbarItemText(gridToolbarItem) | translate"
          [attr.aria-label]="getToolbarItemTitle(gridToolbarItem) | translate"
          [svgIcon]="getSvgIcon(gridToolbarItem)"
          [iconClass]="getIconClass(gridToolbarItem)"
          (itemClick)="handleGridToolbarItemEvent(gridToolbarItem, $event)"
        >
        </kendo-toolbar-dropdownbutton>
      }
      @case (
        getToolbarItemVisibleState(
          gridToolbarItem.visible,
          gridToolBarButtonType.Text
        )
      ) {
        <gk-kendo-toolbar-text
          [title]="getToolbarItemTitle(gridToolbarItem) | translate"
          [attr.aria-label]="getToolbarItemTitle(gridToolbarItem) | translate"
          [text]="getToolbarItemText(gridToolbarItem) | translate"
          [gkButtonClass]="getButtonClass(gridToolbarItem)"
        >
        </gk-kendo-toolbar-text>
      }

      @case (
        getToolbarItemVisibleState(
          gridToolbarItem.visible,
          gridToolBarButtonType.ButtonWithInput
        )
      ) {
        <gk-kendo-toolbar-button-with-input
          [title]="getToolbarItemTitle(gridToolbarItem) | translate"
          [attr.aria-label]="getToolbarItemTitle(gridToolbarItem) | translate"
          [text]="getToolbarItemTitle(gridToolbarItem) | translate"
          [svgIcon]="getSvgIcon(gridToolbarItem)"
          [inputData]="gridToolbarItem.inputData!"
          (clickEvent)="handleGridToolbarItemEvent(gridToolbarItem, $event)"
        >
        </gk-kendo-toolbar-button-with-input>
      }
      @default {
        @if (getToolbarItemVisibleState(gridToolbarItem.visible)) {
          <kendo-toolbar-button
            [showText]="gridToolbarItem.showText || 'both'"
            [disabled]="getToolbarItemDisabledState(gridToolbarItem)"
            [title]="getToolbarItemTitle(gridToolbarItem) | translate"
            [attr.aria-label]="getToolbarItemTitle(gridToolbarItem) | translate"
            [text]="getToolbarItemText(gridToolbarItem) | translate"
            (click)="
              gridToolbarItem.toggleable ||
                handleGridToolbarItemEvent(gridToolbarItem, $event)
            "
            (selectedChange)="
              handleGridToolbarItemEvent(gridToolbarItem, $event)
            "
            [iconClass]="getIconClass(gridToolbarItem)"
            [svgIcon]="getSvgIcon(gridToolbarItem)"
            [className]="getButtonClass(gridToolbarItem)"
            [themeColor]="gridToolbarItem.buttonThemeColor!"
            [toggleable]="gridToolbarItem.toggleable!"
            [selected]="getToolbarItemSelectedValue(gridToolbarItem)!"
          >
          </kendo-toolbar-button>
        }
      }
    }
    @if (
      getToolbarItemVisibleState(gridToolbarItem.visible) &&
      gridToolbarItem.separator
    ) {
      <kendo-toolbar-separator></kendo-toolbar-separator>
    }
  }
</kendo-toolbar>
