import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile, tap } from 'rxjs/operators';
import { DefaultConfigGuest } from '../../../services/default-config-guest/default-config-guest.model';
import { DefaultConfigGuestService } from '../../../services/default-config-guest/default-config-guest.service';
import { LoginFormService } from '../services/login-form/login-form.service';
import { LoginService } from '../services/login/login.service';
import { MessageStatus } from '../services/message-status/message-status.model';
import { NewPasswordService } from '../services/new-password/new-password.service';
import { SessionToolsService } from '../services/session-tools/session-tools.service';
import { TfaConfirmationService } from '../services/tfa-confirmation/tfa-confirmation.service';
import { TfaWebAuthnVerificationService } from '../services/tfa-web-authn-verification/tfa-web-authn-verification.service';
import { SessionToolsBaseComponent } from '../session-tools-base.component';
import { TfaService } from './../../../gk-user-settings/services/tfa/tfa.service';
import { LoginControlName } from './../services/login-form/login-form.model';

enum ExternalLoginLogoAssetUrls {
  Pz = 'assets/logo-pz-2.png',
  LoginGov = 'assets/pz-login-gov.png',
}
@Component({
  selector: 'gk-login',
  templateUrl: './login.component.html',
  styleUrls: ['../session-tools.component.scss', './login.component.scss'],
})
export class LoginComponent
  extends SessionToolsBaseComponent
  implements OnInit, OnDestroy
{
  @Input() signUpLinkVisible: boolean;
  @Input() restorePasswordLinkVisible: boolean;
  @Input() override externalLoginUrl: string;
  @Input() ewidLoginUrl: string;
  @Input() isPzAuthenticationAvailable = true;
  @Input() infoAlertsVisible: boolean;
  isAlive = true;
  externalLoginLogoAssetUrl: string;
  defaultConfigGuest: DefaultConfigGuest;
  passwordVisibility: boolean;
  formGroup: UntypedFormGroup;
  controlNameEnum = LoginControlName;
  successDefaultMessage: string;

  constructor(
    private defaultConfigGuestService: DefaultConfigGuestService,
    private loginFormService: LoginFormService,
    public override loginService: LoginService,
    public override router: Router,
    public override translateService: TranslateService,
    public override newPasswordService: NewPasswordService,
    public override tfaService: TfaService,
    public override tfaConfirmationService: TfaConfirmationService,
    public override sessionToolsService?: SessionToolsService,
    public override tfaWebAuthnVerificationService?: TfaWebAuthnVerificationService,
  ) {
    super(
      translateService,
      router,
      newPasswordService,
      tfaService,
      tfaConfirmationService,
      undefined,
      sessionToolsService,
      tfaWebAuthnVerificationService,
      loginService,
    );
  }

  override ngOnInit(): void {
    this.fetchDefaultConfigGuest();
    this.createForm();
    this.fetchDefaultSuccessMessage();
    this.clearMessageTextOnFormChange();
    super.ngOnInit();
  }

  fetchDefaultConfigGuest(): void {
    this.defaultConfigGuestService
      .getConfig()
      .pipe(
        tap((defaultConfigGuest) => {
          this.defaultConfigGuest = defaultConfigGuest;
        }),
        takeWhile(() => this.isAlive),
      )
      .subscribe(() => {
        this.setProperExternalLoginLogoAssetUrl();
      });
  }

  createForm(): void {
    this.formGroup = this.loginFormService.getFormGroup();
  }

  fetchDefaultSuccessMessage(): void {
    this.translateService
      .get('SESSION_TOOLS.SUCCESS')
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((message) => {
        this.successDefaultMessage = message;
      });
  }

  clearMessageTextOnFormChange(): void {
    this.formGroup.valueChanges
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(() => {
        this.messageText = '';
      });
  }

  togglePasswordVisibility(shouldBeVisible: boolean): void {
    this.passwordVisibility = shouldBeVisible;
  }

  setProperExternalLoginLogoAssetUrl(): void {
    this.externalLoginLogoAssetUrl = this.defaultConfigGuest
      .isLoginGovConfigured
      ? ExternalLoginLogoAssetUrls.LoginGov
      : ExternalLoginLogoAssetUrls.Pz;
  }

  loginSubmitHandle(): void {
    this.messageText = '';
    this.actionRequestInProgress = true;
    this.submitted = true;
    if (!this.formGroup.valid) {
      this.actionRequestInProgress = false;
      return;
    }
    const formRawValue = this.formGroup.getRawValue();
    this.newPasswordService.currentUserLogin.next(formRawValue.login);
    this.loginService
      .login(formRawValue, this.ewidLoginUrl)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe({
        next: (data) => {
          this.handleSuccessLoginResponse(data);
        },
        error: () => {
          this.setMessageStatus(MessageStatus.Error);
          this.messageText = this.errorDefaultMessage;
          this.actionRequestInProgress = false;
        },
      });
  }

  shouldShowRequiredMessage(formControlName: LoginControlName): boolean {
    return (
      this.isFormControlReadyToShowAlert(formControlName) &&
      this.getFormControlError(formControlName, 'required')
    );
  }

  shouldShowMinLengthMessage(formControlName: LoginControlName): boolean {
    return (
      this.isFormControlReadyToShowAlert(formControlName) &&
      this.getFormControlError(formControlName, 'minlength')
    );
  }

  getFormControlError(
    formControlName: LoginControlName,
    errorName: string,
  ): any {
    const formControlErrors = this.getFormControlByName(formControlName).errors;

    return formControlErrors && formControlErrors[errorName];
  }

  isFormControlReadyToShowAlert(formControlName: LoginControlName): boolean {
    const control = this.getFormControlByName(formControlName);
    return this.submitted && control.invalid;
  }

  getFormControlByName(formControlName: LoginControlName): AbstractControl {
    return this.formGroup.get(formControlName);
  }

  openExternalLoginPage(): void {
    this.loginService.navigateToExternalLogin(this.externalLoginUrl);
  }
}
