import { Injectable } from '@angular/core';
import { PortalId } from '@gk/gk-modules';
import { Observable, of } from 'rxjs';
import { PortalConfigGetter } from '../../services/portal-config/portal-config.interface';
import {
  defaultPortalConfig,
  PortalConfig,
} from '../../services/portal-config/portal-config.model';

@Injectable({
  providedIn: 'root',
})
export class InterModConfigService implements PortalConfigGetter {
  getPortalConfig(): Observable<PortalConfig> {
    return of({
      ...defaultPortalConfig,
      isPerunAuthenticationAvailable: true,
      isPzAuthenticationAvailable: true,
      portalId: PortalId.InterMod,
      redirectCookieEnabled: true,
      isNavbarVisible: false,
    });
  }
}
