import { NavItemsSectionConfig, PortalId, SessionType } from '@gk/gk-modules';
import { PzInitRoutes } from '../../guards/guard.models';

export interface PortalConfig {
  pzInitRoute?: PzInitRoutes;
  navSections?: NavItemsSectionConfig[];
  isPerunAuthenticationAvailable?: boolean;
  isPzAuthenticationAvailable?: boolean;
  sessionToolsInfoAlertsVisible?: boolean;
  departmentId?: number;
  sessionTypes?: SessionType[];
  accessKeys?: string[];
  portalId?: PortalId;
  redirectCookieEnabled?: boolean;
  isNavbarVisible?: boolean;
}

export const defaultPortalConfig: PortalConfig = {
  pzInitRoute: PzInitRoutes.PzInitSystemUrl,
  isPerunAuthenticationAvailable: false,
  isPzAuthenticationAvailable: false,
  sessionToolsInfoAlertsVisible: false,
  redirectCookieEnabled: false,
  isNavbarVisible: true,
};
